import Box from "@mui/material/Box";
import { Grid, Pagination, Paper, Popper, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CircularLoader from "../../common/CircularLoader";
import CheckStatusPreview from "../Candidates/CandidatesTable/Cells/CheckStatusPreview";

const DisplayingTabularDataUsingCards = ({
  rowId,
  headingFields,
  rows,
  columns,
  pageSize,
  page,
  loading = false,
  totalCount,
  handlePageChange,
}) => {
  const [customSortingModel, setCustomSortingModel] = useState({
    field: "",
    sort: "default",
  });
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setCustomSortingModel({
      field: searchParams.get("orderBy"),
      sort: searchParams.get("orderDirection") || "default",
    });
  }, [searchParams.get("orderBy"), searchParams.get("orderDirection")]);

  const handleCustomSort = (newField) => {
    let { field, sort } = customSortingModel;
    let newSort;

    //if new field is selected then we will start with 'asc' sort
    if (newField !== field) {
      newSort = "asc";
    } else {
      switch (sort) {
        case "default":
          newSort = "asc";
          break;
        case "asc":
          newSort = "desc";
          break;
        case "desc":
        default:
          newSort = "default";
          break;
      }
    }

    setCustomSortingModel({ field: newField, sort: newSort });
    handleSortingApiCall(newField, newSort === "default" ? "" : newSort);
  };

  // Customize the column headers with icons based on the sort state

  let isColumnActionPresent = useMemo(
    () => columns?.find((curr) => curr?.type === "actions"),
    [columns]
  );

  return (
    <>
      {loading ? (
        <CircularLoader size={25} height="70vh" />
      ) : (
        <Box
          sx={{ height: "70vh", width: "100%", overflow: "auto" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {rows?.length
            ? rows?.map((rowData) => (
                <Box
                  key={rowData[rowId]}
                  container
                  sx={{
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                    borderRadius: "5px",
                    maxWidth: 1200,
                  }}
                  mb={2}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection={{ xs: "column", md: "row" }}
                    columnGap={3}
                    px={2}
                    mt={0.5}
                    mb={{ xs: 2, md: 0 }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      {headingFields.map((field, index) => (
                        <Box
                          key={field}
                          fontWeight={550}
                          fontSize="12px"
                          sx={{
                            color: "#616161",
                            maxWidth: {
                              xs: "80px",
                              sm: "180px",
                              md: "120px",
                              lg: "210px",
                            },
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            component="span"
                            sx={{
                              color:
                                index === 1 || index === 2
                                  ? (theme) => theme.palette.dark.main
                                  : (theme) => theme.palette.primary.main,
                              fontWeight:
                                index === 1 || index === 2 ? 500 : 550,
                              fontSize:
                                index === 1 || index === 2 ? "11px" : "12px",
                            }}
                          >
                            {index === 1
                              ? `\u00A0( ${rowData[field]}`
                              : index === 2
                              ? `, ${rowData[field]} )`
                              : rowData[field]}
                          </Typography>
                        </Box>
                      ))}
                      &nbsp;
                      {rowId === "candidatesCasesId" && (
                        <Box
                          sx={{ transform: "scale(0.75)", marginLeft: "-15px" }}
                        >
                          <CheckStatusPreview
                            data={rowData}
                            assignedChecks={rowData.assignedCheck}
                            assignedSubChecks={rowData.assignedSubChecks}
                            selectedPackageName={
                              rowData?.assignedPackage?.packageName
                            }
                          />
                        </Box>
                      )}
                    </Box>

                    <Box display="flex" alignItems="center">
                      <Box
                        width={{ xs: "100%", md: "auto" }}
                        display="flex"
                        pl={2}
                      >
                        {isColumnActionPresent
                          ?.getActions(rowData)
                          ?.map((actionData, ind) => (
                            <Box
                              key={ind}
                              onClick={() =>
                                actionData.disabled ? {} : actionData.onClick()
                              }
                              sx={{ cursor: "pointer" }}
                              display="flex"
                              justifyContent="center"
                              p={0.5}
                            >
                              {actionData.icon}
                            </Box>
                          ))}
                      </Box>{" "}
                    </Box>
                  </Box>
                  <Grid
                    container
                    display="flex"
                    justifyContent="flex-start"
                    sx={{ padding: "10px 15px" }}
                    rowGap={0.5}
                  >
                    {columns
                      ?.filter((curr) => curr?.type !== "actions")
                      ?.map((columnData, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          xl={3}
                          key={index}
                          sx={{
                            minWidth: 200,
                          }}
                          display="flex"
                          alignItems="center"
                        >
                          <Typography
                            fontSize="10px"
                            textAlign="left"
                            fontWeight={500}
                            color="#000"
                          >
                            {columnData?.headerName}&nbsp;:&nbsp;
                          </Typography>
                          <Typography fontSize="10px">
                            {columnData?.renderCustomValue
                              ? columnData?.renderCustomValue(rowData)
                              : rowData[columnData?.field]}
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              ))
            : Array.isArray(rows) && (
                <Typography fontWeight={550} textAlign="center" mt={5}>
                  No Data Found
                </Typography>
              )}
        </Box>
      )}
      <Box display="flex" justifyContent="center" mt={1}>
        <Pagination
          size="small"
          count={Math.ceil(totalCount / pageSize)}
          page={page}
          onChange={handlePageChange}
          showFirstButton
          showLastButton
        />
      </Box>
    </>
  );
};

export default DisplayingTabularDataUsingCards;
