import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import { rangeSelectors } from "../constants/filterData";
import { LaptopDatePicker2 } from "../../common/Form/DatePicker/DesktopDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationTeams } from "../../store/actions/hrActions";
import { getLoggedInUserHrOrganizationId } from "../../utils/UserHelper";
import { useTheme } from "@mui/material/styles";
import { getCurrentFileNameAndFunction } from "../../utils/getCurrentFileNameAndFunction";
import {
  getVerificationProcessStatus,
  getVerificationResultStatus,
} from "../../store/actions/operationActions";
import CircularLoader from "../../common/CircularLoader";
import { CustomCheckbox } from "./CustomCheckBox";
import ScrollableGrid from "../../common/ScrollableGrid";
import { useLocation, useSearchParams } from "react-router-dom";

const BaseSelect = styled(Select)`
  fieldset {
    border-radius: 12px;
    border-color: #e7e7e7;
  }
`;

const BaseListItem = styled(ListItemButton)`
  border-radius: 12px;
  &.Mui-selected {
    color: white;
    background-color: #5974f6;
    &:hover {
      background-color: #5974f6;
    }
  }
`;

const TeamRangeSelection = () => {
  const loggedInUser = useSelector((state) => state.authorization.currentUser);
  const [range, setRange] = useState();
  const [verificationProcessStatusState, setVerificationProcessStatusState] =
    useState({});
  const [verificationProcessResultState, setVerificationProcessResultState] =
    useState({});

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const { organizationTeams: organizationTeamsData, customDateFilterError } =
    useSelector((state) => state?.hr);

  const { verificationProcessStatusData, verificationResultStatusData } =
    useSelector((state) => state.operations);

  // const [processStatusSwitch, setProcessStatusSwitch] = useState("1");
  // const [resultStatusSwitch, setResultStatusSwitch] = useState("1");

  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    if (organizationTeamsData?.length === 0) {
      const params = {
        orgId: getLoggedInUserHrOrganizationId(),
      };

      let logDetails = getCurrentFileNameAndFunction(
        import.meta.url,
        "useEffect"
      );

      dispatch(getOrganizationTeams(params, logDetails));
    }
    setRange(rangeSelectors[+searchParams.get("rangeValue")]);
    setFilter({
      hrTeamId: searchParams.get("hrTeamId"),
      fromDate: searchParams.get("fromDate"),
      toDate: searchParams.get("toDate"),
    });
  }, [
    searchParams.get("hrTeamId"),
    searchParams.get("rangeValue"),
    searchParams.get("fromDate"),
    searchParams.get("toDate"),
  ]);

  useEffect(() => {
    if (!verificationProcessStatusData || !verificationResultStatusData) {
      let logDetails = getCurrentFileNameAndFunction(
        import.meta.url,
        "useEffect"
      );

      dispatch(getVerificationProcessStatus(logDetails));
      dispatch(getVerificationResultStatus(logDetails));
    }
  }, []);

  //adding 'All' for checkbox in process & result list
  let verificationProcessStatusLists = useMemo(() => {
    if (verificationProcessStatusData) {
      return [
        {
          candidatesVerificationProcessStatusId: 0,
          verificationProcessStatusName: "All",
          verificationProcessStatusDescription: "All checks",
        },
        ...verificationProcessStatusData,
      ];
    } else {
      return [];
    }
  }, [verificationProcessStatusData]);

  let verificationProcessResultLists = useMemo(() => {
    if (verificationResultStatusData) {
      return [
        {
          candidatesVerificationResultStatusId: 0,
          verificationResultStatusName: "All",
          verificationResultStatusDescription: "All checks",
        },
        ...verificationResultStatusData,
      ];
    } else {
      return [];
    }
  }, [verificationResultStatusData]);

  useEffect(() => {
    //verification
    let verificationIds = searchParams.get("verificationProcessId")?.split(",");

    if (verificationIds && verificationProcessStatusLists?.length) {
      let tempVerification = {};

      verificationProcessStatusLists?.forEach((curr) => {
        tempVerification = {
          ...tempVerification,
          [curr?.candidatesVerificationProcessStatusId]: false,
        };
      });

      // if (
      //   verificationIds?.length ===
      //   verificationProcessStatusLists?.length - 1
      // ) {
      //   tempVerification = { 0: true };
      // }

      for (let id of verificationIds) {
        tempVerification = { ...tempVerification, [+id]: true };
      }

      setVerificationProcessStatusState((prev) => (prev = tempVerification));
    }

    //result
    let resultIds = searchParams.get("verificationResultId")?.split(",");

    if (resultIds && verificationProcessResultLists?.length) {
      let tempResult = {};

      verificationProcessResultLists?.forEach((curr) => {
        tempResult = {
          ...tempResult,
          [curr?.candidatesVerificationResultStatusId]: false,
        };
      });

      // if (resultIds?.length === verificationProcessResultLists?.length - 1) {
      //   tempResult = { 0: true };
      // }

      for (let id of resultIds) {
        tempResult = { ...tempResult, [+id]: true };
      }

      setVerificationProcessResultState((prev) => (prev = tempResult));
    }
  }, [
    verificationProcessStatusLists,
    verificationProcessResultLists,
    searchParams,
  ]);

  const setFilter = useCallback(
    (filters) => {
      setSearchParams((prevParams) => {
        return new URLSearchParams({
          ...Object.fromEntries(prevParams.entries()),
          ...filters,
          pageNumber: 1,
          displayLoader: "show",
        });
      });
    },
    [searchParams]
  );

  const handleProcessStatusToggle = () => {
    // setProcessStatusSwitch((prev) => {
    defaultToggleCheckBoxes(
      0,
      verificationProcessStatusState,
      setVerificationProcessStatusState,
      verificationProcessStatusLists,
      "candidatesVerificationProcessStatusId",
      "verificationProcessId",
      {
        2: true,
        3: true,
      },
      {
        processStatusSwitch:
          searchParams.get("processStatusSwitch") === "1" ? "0" : "1",
      }
    );

    //   return prev === "1" ? "0" : "1";
    // });
  };

  const handleResultStatusToggle = () => {
    // setResultStatusSwitch((prev) => {
    defaultToggleCheckBoxes(
      0,
      verificationProcessResultState,
      setVerificationProcessResultState,
      verificationProcessResultLists,
      "candidatesVerificationResultStatusId",
      "verificationResultId",
      null,
      {
        resultStatusSwitch:
          searchParams.get("resultStatusSwitch") === "1" ? "0" : "1",
      }
    );

    //   return prev === "1" ? "0" : "1";
    // });
  };

  const onClickRange = (item, index) => {
    setRange(item);
    setFilter({ ...item.dates, periodCode: item?.label, rangeValue: index });
  };

  const onChangeDate = (type) => (date) => {
    setRange(rangeSelectors[5]);
    setFilter({
      [type]: moment(date).format("YYYY-MM-DD"),
      periodCode: rangeSelectors[5]?.label,
      rangeValue: 5,
    });
  };

  const defaultToggleCheckBoxes = (
    id,
    checkBoxState,
    setCheckBoxState,
    checkboxListsData,
    checkboxListId,
    paramName,
    defaultSelectedChecks = { 3: true },
    toggalValues = {}
  ) => {
    let temp = { ...checkBoxState };

    temp = { ...temp, [id]: !temp[id] };

    if (id === 0) {
      if (Object.values(toggalValues)?.[0] === "0") {
        checkboxListsData.forEach((curr) => {
          if (curr[checkboxListId] !== 0) {
            temp = { ...temp, [curr[checkboxListId]]: true };
          }
        });
      } else {
        checkboxListsData.forEach((curr) => {
          temp = { ...temp, [curr[checkboxListId]]: false };
        });
        //will only make the default checkbox ticked
        temp = { ...temp, ...defaultSelectedChecks };
      }
      //'All' is selected
      // if (temp[id]) {
      //   //making every checkbox ticked true
      //   checkboxListsData.forEach((curr) => {
      //     if (curr[checkboxListId] !== 0) {
      //       temp = { ...temp, [curr[checkboxListId]]: true };
      //     }
      //   });
      // } else {
      //   //'All' is deselected

      //   //making every checkbox ticked false
      //   checkboxListsData.forEach((curr) => {
      //     temp = { ...temp, [curr[checkboxListId]]: false };
      //   });

      //   //will only make the default checkbox ticked
      //   temp = { ...temp, ...defaultSelectedChecks };
      // }
    }
    // else {
    //   let countTickedChecks = 0;
    //   //counting number of ticked checkboxes
    //   for (let i in temp) {
    //     if (i !== "0" && temp[i]) countTickedChecks++;
    //   }

    //   //if countTickedChecks is equal to the number of total checkboxes - 1 ,
    //   //then we will make 'All' checkbox true else false
    //   //subtracting 1 from checkboxListsData because it has an extra object for 'All'
    //   temp = { ...temp, 0: countTickedChecks === checkboxListsData.length - 1 };
    // }

    setCheckBoxState(temp);

    //adding selected ids
    let tempArr = [];
    for (let i in temp) {
      if (i !== "0" && temp[i]) {
        //not adding 'All' checkbox
        tempArr.push(i);
      }
    }

    setFilter({
      [paramName]: tempArr.join(","),
      ...toggalValues,
    });
  };

  return (
    <ScrollableGrid screen="xxl" scrollHeight="85vh">
      <Box pr={1}>
        {/* TEAM */}
        <FormControl fullWidth size="small">
          <Box
            fontWeight="bold"
            margin="0 0 0.5rem 0.5rem"
            color={theme.palette.grey[700]}
            htmlFor=""
          >
            Select Team
          </Box>
          <BaseSelect
            fullWidth
            value={searchParams.get("hrTeamId")}
            onChange={(e) => setFilter({ hrTeamId: e.target.value })}
          >
            <MenuItem key="All" value="All">
              All Teams
            </MenuItem>
            {organizationTeamsData?.map((team) => {
              return (
                <MenuItem key={team.hrTeamsId} value={team.hrTeamsId}>
                  {team.hrTeamName}
                </MenuItem>
              );
            })}
          </BaseSelect>
        </FormControl>

        {/* RANGE */}
        <FormControl fullWidth size="small">
          <Box
            color={theme.palette.grey[700]}
            fontWeight="bold"
            margin="2rem 0 0.5rem 0.5rem"
          >
            Select Range
          </Box>
          <List disablePadding>
            {rangeSelectors.map((item, index) => (
              <BaseListItem
                key={item.value}
                selected={item.value === range?.value}
                onClick={() => onClickRange(item, index)}
              >
                <ListItemText
                  primary={item.label}
                  sx={{
                    "> span": {
                      fontWeight: 500,
                      color:
                        item.value === range?.value
                          ? "white !important"
                          : "#817F89 !important",
                    },
                  }}
                />
              </BaseListItem>
            ))}
          </List>
          {range?.value === "CUSTOM RANGE" && (
            <Paper sx={{ padding: "1rem 0.5rem" }}>
              {/*<Typography fontWeight={500} color="#817F89">*/}
              {/*    Custom*/}
              {/*</Typography>*/}
              <Grid container direction="row">
                <Grid xs={12} sx={{ paddingRight: "0.25rem" }}>
                  <Typography
                    color={theme.palette.grey[700]}
                    marginBottom="4px"
                    fontSize="13px"
                    fontWeight={500}
                  >
                    From
                  </Typography>
                  <LaptopDatePicker2
                    name="from"
                    label="From"
                    required={true}
                    value={searchParams.get("fromDate") || ""}
                    onChange={onChangeDate("fromDate")}
                  />
                </Grid>
                <Grid xs={12}>
                  <Typography
                    color={theme.palette.grey[700]}
                    marginBottom="4px"
                    fontSize="13px"
                    fontWeight={500}
                  >
                    To
                  </Typography>
                  <LaptopDatePicker2
                    name="to"
                    label="To"
                    required={true}
                    value={searchParams.get("toDate") || ""}
                    onChange={onChangeDate("toDate")}
                  />
                </Grid>
              </Grid>
              {customDateFilterError ? (
                <Typography
                  color="error"
                  mt={1}
                  fontSize="10px"
                  textAlign="center"
                >
                  {customDateFilterError}
                </Typography>
              ) : null}
            </Paper>
          )}
        </FormControl>

        {(searchParams.get("view") !== "Dashboard" &&
          searchParams.get("view") !== "performance_insights") ||
        location.pathname.includes("/hr/candidates") ? (
          <>
            {/* Verification process */}
            <FormControl fullWidth size="small">
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent={{ xs: "center", sm: "space-between" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
              >
                <Box
                  color={theme.palette.grey[700]}
                  fontWeight="bold"
                  margin="0.5rem 0 0.5rem 0.5rem"
                >
                  Verification Process
                </Box>
                <Switch
                  size="small"
                  checked={searchParams.get("processStatusSwitch") === "1"}
                  onChange={handleProcessStatusToggle}
                  inputProps={{ "aria-label": "small switch" }}
                />
              </Box>

              {searchParams.get("processStatusSwitch") === "1" && (
                <List>
                  {verificationProcessStatusLists?.length ? (
                    <>
                      {verificationProcessStatusLists?.map((curr, ind) => (
                        <>
                          {curr.candidatesVerificationProcessStatusId !== 0 && (
                            <ListItem
                              key={ind}
                              disablePadding
                              sx={{
                                padding: "0px",
                                height: "2rem",
                                marginBottom: "5px",
                              }}
                            >
                              <FormGroup
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <CustomCheckbox
                                  type="checkbox"
                                  id={`process-checkbox-${ind}`}
                                  name={curr?.verificationProcessStatusName}
                                  value={
                                    verificationProcessStatusState[
                                      curr
                                        ?.candidatesVerificationProcessStatusId
                                    ]
                                  }
                                  checked={
                                    verificationProcessStatusState[
                                      curr
                                        ?.candidatesVerificationProcessStatusId
                                    ]
                                  }
                                  onChange={() =>
                                    defaultToggleCheckBoxes(
                                      curr?.candidatesVerificationProcessStatusId,
                                      verificationProcessStatusState,
                                      setVerificationProcessStatusState,
                                      verificationProcessStatusLists,
                                      "candidatesVerificationProcessStatusId",
                                      "verificationProcessId",
                                      {
                                        2: true,
                                        3: true,
                                      }
                                    )
                                  }
                                />
                                <label htmlFor={`process-checkbox-${ind}`}>
                                  {" "}
                                  <Typography
                                    variant="body2"
                                    sx={{ fontSize: "12px" }}
                                  >
                                    {curr?.verificationProcessStatusName}
                                  </Typography>
                                </label>
                              </FormGroup>
                            </ListItem>
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    <CircularLoader size={30} height="10vh" />
                  )}
                </List>
              )}
            </FormControl>

            {/* Verification result */}
            <FormControl fullWidth size="small">
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent={{ xs: "center", sm: "space-between" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
              >
                <Box
                  color={theme.palette.grey[700]}
                  fontWeight="bold"
                  margin="0.5rem 0 0.5rem 0.5rem"
                >
                  Verification Result
                </Box>
                <Switch
                  size="small"
                  checked={searchParams.get("resultStatusSwitch") === "1"}
                  onChange={handleResultStatusToggle}
                  inputProps={{ "aria-label": "small switch" }}
                />
              </Box>
              {searchParams.get("resultStatusSwitch") === "1" && (
                <List>
                  {verificationProcessResultLists?.length ? (
                    <>
                      {verificationProcessResultLists?.map((curr, ind) => (
                        <>
                          {curr.candidatesVerificationResultStatusId !== 0 && (
                            <ListItem
                              key={ind}
                              disablePadding
                              sx={{
                                padding: "0px",
                                height: "2rem",
                                marginBottom: "5px",
                              }}
                            >
                              <FormGroup
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  flexWrap: "nowrap",
                                }}
                              >
                                <CustomCheckbox
                                  type="checkbox"
                                  id={`result-checkbox-${ind}`}
                                  name={
                                    curr?.candidatesVerificationResultStatusId
                                  }
                                  value={
                                    verificationProcessResultState[
                                      curr?.candidatesVerificationResultStatusId
                                    ]
                                  }
                                  checked={
                                    verificationProcessResultState[
                                      curr?.candidatesVerificationResultStatusId
                                    ]
                                  }
                                  onChange={() =>
                                    defaultToggleCheckBoxes(
                                      curr?.candidatesVerificationResultStatusId,
                                      verificationProcessResultState,
                                      setVerificationProcessResultState,
                                      verificationProcessResultLists,
                                      "candidatesVerificationResultStatusId",
                                      "verificationResultId"
                                    )
                                  }
                                />
                                <label htmlFor={`result-checkbox-${ind}`}>
                                  {" "}
                                  <Typography
                                    variant="body2"
                                    sx={{ fontSize: "12px" }}
                                  >
                                    {curr?.verificationResultStatusName}
                                  </Typography>
                                </label>
                              </FormGroup>
                            </ListItem>
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    <CircularLoader size={30} height="10vh" />
                  )}
                </List>
              )}
            </FormControl>
          </>
        ) : null}
      </Box>
    </ScrollableGrid>
  );
};

export default TeamRangeSelection;
